import { render, staticRenderFns } from "./districts.vue?vue&type=template&id=360d666d&scoped=true"
import script from "./districts.component.js?vue&type=script&lang=js&external"
export * from "./districts.component.js?vue&type=script&lang=js&external"
import style0 from "./districts.vue?vue&type=style&index=0&id=360d666d&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "360d666d",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VAutocomplete,VBtn,VCheckbox,VCombobox,VDataTable,VDivider,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VForm,VIcon,VLayout,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VMenu,VNavigationDrawer,VSwitch,VTextField})
